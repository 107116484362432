.sessionExp {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  // height: 100vh;
  // background-color: aqua;

  .continer {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin:auto 0;
    padding: 20px;
    height: 50%;
    justify-content: center;
    justify-items: center;
    align-items: center;

    .btn {
      background-color: #343A40;
      color: white;
      padding: 10px 15px;
      margin: 0px;
      display: flex;
      justify-items: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      div {
        width: 32px;
        height: 32px;
      }
    }

    .icon {
      width: 100px;
      height: 100px;
    }

  }
}